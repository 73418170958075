import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  FormControl,
  Typography,
  Link,
} from "@mui/material";
import Dropzone from "react-dropzone";
import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../components/common";
import localConfig from "local-storage";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import CheckResultControl from "./CheckResultControl";
import RevisedRobDialog from "./RevisedRobDialog";
import WarningConfirmDialog from "./WarningConfirmDialog";
import { CheckBox, ConstructionOutlined } from "@mui/icons-material";
import ImportReportDialog from "./ImportReportDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      ReportTypeID: "Report type:",
      ReportTime: "Report time",
      ReportTypeID: "Report type",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Thời gian:",
      ReportTypeID: "Loại báo cáo",
    };
const buttonReports = [
  {
    ID: "DR",
    Label: "Departure Report",
    LabelEN: "Departure Report",
  },
  {
    ID: "NS",
    Label: "Noon At Sea Report",
    LabelEN: "Noon At Sea Report",
  },
  {
    ID: "AR",
    Label: "Arrival Report",
    LabelEN: "Arrival Report",
  },
  {
    ID: "AF",
    Label: "All Fast Report",
    LabelEN: "All Fast Report",
  },
  {
    ID: "NP",
    Label: "Noon At Port Report",
    LabelEN: "Noon At Port Report",
  },
  {
    ID: "SR",
    Label: "Shifting Report",
    LabelEN: "Shifting Report",
  },
];
const buttonLabels = englishUI
  ? {
      btnThem: "Add",
      btnRead: "Read",
      btnApprove: "Approve/Cancel",

      btnTimKiem: "Search",
      btnReportError: "Failed Report",
      btnCalculate: "Report",
      btnMarkCalculate: "Re-Calculate",
      btnMarkCheckRule: "Re-Check rule",
      btnXuly: "Process",
      btnHieuChinh: "Revised rob",
      btnImport: "Import",
    }
  : {
      btnThem: "Thêm",
      btnRead: "Đã đọc",
      btnApprove: "Duyệt/Hủy",
      btnTimKiem: "Tìm kiếm",
      btnReportError: "BC lỗi",
      btnCalculate: "Run. Report",
      btnMarkCalculate: "Tính lại",
      btnMarkCheckRule: "Tính cảnh báo",
      btnXuly: "Xử lý",
      btnHieuChinh: "Hiệu chỉnh",
      btnImport: "Import",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "TripCode",
    type: "string",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Trip Code" : "Hành trình",
    render: ({ data }) =>
      data.HieuChinh > 0 ? (
        <FormLabel style={{ color: "#bf00ff" }}>{data.TripCode}</FormLabel>
      ) : (
        <FormLabel>{data.TripCode}</FormLabel>
      ),
  },
  {
    name: "ReportTypeName",
    type: "string",
    headerAlign: "center",
    defaultWidth: 80,
    header: englishUI ? "Type" : "Loại báo cáo",
    render: ({ data }) =>
      data.TempStatus == 0 ? (
        <FormLabel>{data.ReportTypeName}</FormLabel>
      ) : data.TempStatus == 1 ? (
        data.DataStatus == 1 ? (
          <FormLabel style={{ color: "#0000ff" }}>
            {data.ReportTypeName}
          </FormLabel>
        ) : data.ReportStatus == 1 ? (
          <FormLabel style={{ color: "#32a846" }}>
            {data.ReportTypeName}
          </FormLabel>
        ) : (
          <FormLabel
            style={{ color: "#112233", textDecoration: "line-through" }}
          >
            {data.ReportTypeName}
          </FormLabel>
        )
      ) : (
        <FormLabel style={{ color: "#ff0000" }}>
          {data.ReportTypeName}
        </FormLabel>
      ),
  },
  {
    name: "ReportDataTime",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Trip. Time" : "Thời gian",
    render: (props) => {
      return props.data.ReportDataTime2
        ? moment(props.data.ReportDataTime2).format("HH:mm DD/MM/YYYY")
        : props.data.ReportDataTime
        ? moment(props.data.ReportDataTime).format("HH:mm DD/MM/YYYY")
        : null;
    },
  },
  {
    name: "ReportTime",
    type: "date",
    headerAlign: "center",
    defaultWidth: 130,
    header: englishUI ? "Report" : "Báo cáo",
    render: (props) =>
      props.data.ReportTime ? (
        props.data.Calculated == 1 || props.data.DataStatus == 0 ? (
          moment(props.data.ReportTime).format("HH:mm DD/MM/YYYY")
        ) : (
          <FormLabel style={{ color: "#ff0000" }}>
            {moment(props.data.ReportTime).format("HH:mm DD/MM/YYYY")}
          </FormLabel>
        )
      ) : null,
  },
  {
    name: "ReportStatusName",
    type: "string",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Status" : "Trạng thái",
    render: undefined,
  },
  {
    name: "DailyReportID",
    type: "number",
    headerAlign: "center",
    defaultWidth: 100,
    header: englishUI ? "ID" : "ID",
    render1: ({ data }) => (
      <FormLabel
        style={
          data.CheckCount
            ? {
                backgroundColor:
                  data.CheckDone < data.CheckCount ? "#ff0000" : "#a87332",
              }
            : undefined
        }
      >
        {data.ParentReportID
          ? data.DailyReportID + "/" + data.ParentReportID
          : data.DailyReportID}
      </FormLabel>
    ),
    render: ({ data }) => (
      <FormLabel>
        {data.ParentReportID
          ? data.DailyReportID + "/" + data.ParentReportID
          : data.DailyReportID}
      </FormLabel>
    ),
  },
  {
    name: "NguoiCN",
    type: "string",

    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Uploaded by" : "Người CN",
    render: undefined,
  },
  {
    name: "NgayCN",
    type: "date",

    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:MM DD/MM/YYYY",
    header: englishUI ? "Uploaded time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
  {
    name: "Checked",
    type: "number",

    headerAlign: "center",
    defaultWidth: 85,
    header: englishUI ? "Rule checked" : " Rule checked",
    render: (props) => (
      <Checkbox
        size="medium"
        sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
        checked={props.data.Checked}
      />
    ),
  },
  {
    name: "IDReport",
    type: "number",

    headerAlign: "center",
    defaultWidth: 85,
    header: englishUI ? "Temp ID" : " Temp ID",
    render: undefined,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "ReportDataTime", dir: 0, type: "date" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

////////////////////////////////////////////////////////////////////////////
class TempReportPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      DR_TempReport_Error: [], //danh sach temp report co van de
      UnCalculatedReport: 0, //danh sach cac report chua tinh toan
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: localStorage.getItem("TempReportPage.PageSize") ?? 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              VoyageCode: null,
              ReportTime1: null,
              ReportTime2: null,
              CheckCount: 0, //tim report co van de
              RevisedROB: 0,
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha

      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      showPreview:
        localStorage.getItem("TempReportPage.PageSize") === "false"
          ? false
          : true,
      ReportContent: "",
      CheckLogs: "", //danh sach cac ket qua check cua 1 report
      CheckResults: [], //danh sach ket qua canh bap cho tung report
      Attachs: [], //file dinh kem cua report
      showPreview: localConfig.get("TempReportPage.showPreview"),
      hasQuyen: global.hasRoles("R211"),
      QuyenXL: {
        //quyen xu ly canh bao
        PhuTrach1: global.hasRoles("R221") ? 1 : 0, //phu trach chuyen vien
        PhuTrach2: global.hasRoles("R222") ? 2 : 0, //phu trach phong
        PhuTrach3: global.hasRoles("R223") ? 3 : 0, //phu trach lanh dao
      },
      show_mark_calculate: false,
      show_mark_checkrule: false,
    };
    this.state.QuyenXL.PhuTrach = this.state.QuyenXL.PhuTrach3
      ? 3
      : this.state.QuyenXL.PhuTrach2
      ? 2
      : this.state.QuyenXL.PhuTrach1
      ? 1
      : 0;
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "VoyageCode" &&
      fieldName !== "ReportTypeID" &&
      fieldName !== "CheckCount"
    ) {
      this.setMyState(this.state, () => this.doSearch());
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy || obj.VoyageCode) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s, () => this.doSearch());
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {
      this.doSearch();
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.DanhMuc.DM_Voyage = [];
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.loadDanhMuc(() => {
        this.doSearch();
      });
    }
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(nextFunc) {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Voyage_GetByShip",
        ThamSo: {
          ShipID: this.props.ShipData.ShipID,
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...this.state.DanhMuc,
              ...response,
            },
            after: {
              ...this.state.after,
              VoyageCode:
                response.DM_Voyage.length > 0
                  ? response.DM_Voyage[0].VoyageCode
                  : null,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_ReportType",
        },
      })
      .then((response) => {
        this.setState({
          DanhMuc: {
            ...this.state.DanhMuc,
            ...response,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
    /*      server
      .post("Email/DecryptPwd", "QfQ2RQdedhU5/0djSIMOTQ ==")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });*/
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_TempReport_Search",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          var tempReport = response.TempReport; //danh sach du lieu report co van de chua xu ly
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            DR_TempReport_Error: tempReport,
            UnCalculatedReport: response.CalculateReport[0].SoLuong, //danh sach report chua calculate
            ReportContent: "",
            CheckLogs: "",
            CheckResults: [],
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    localStorage.setItem("TempReportPage.PageSize", this.state.after.PageSize);
    localStorage.setItem("TempReportPage.showPreview", this.state.showPreview);
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };
  loadPreview() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_DailyReport_Get_PreviewAndChecks",
        ThamSo: {
          ReportID: this.state.rowSelected.ReportID,
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        var logs = "";
        var stt = 1;
        response.CheckResults.map(
          (r) => (logs += stt++ + ") " + r.RuleName + "\n")
        );
        this.setState({
          ReportContent:
            response.ReportContents.length > 0
              ? response.ReportContents[0].PreviewText
              : "",
          CheckLogs: logs,
          CheckResults: response.CheckResults,
          Attachs: response.Attachs,
        });
      })
      .catch((error) => {
        showError(error);
      });
  }

  requestDuyet() {
    //load de hien thi preview report
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_DailyReport_Approve",
        ThamSo: {
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        this.setState(
          {
            show_config_approve: false,
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({
          saving: false,
        });
        showError(error);
      });
  }
  requestXuLy() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_TempReport_Process",
        ThamSo: {
          ReportID_Filter: this.state.rowSelected.ReportID,
          Reset: true,
        },
      })
      .then((response) => {
        this.setState(
          {
            show_confirm_process: false,
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({ show_confirm_process: false, saving: false });
        showError(error);
      });
  }
  //Thuc hien calculate lai cac report
  doRunReport() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_DailyReport_DoCalculate",
        ThamSo: {
          ReportID_Filter: 0,
        },
      })
      .then((response) => {
        this.doSearch(); //refresh lai du lieu
      })
      .catch((error) => {
        showError(error);
      });
  }
  //thuc hien import file
  doImport(file) {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const json = Buffer.from(text).toString();
        this.doUpload(json);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  }
  //upload du lieu report
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_TempReport_Upload",
        ThamSo: [JSON.parse(json)],
      })
      .then((response) => {
        this.setState(
          {
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }
  requestMarkCalculate() {
    //danh dau cac report phai thuc hien tinh toan lai du lieu

    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_DailyReport_MarkCalculate",
        ThamSo: {
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        this.setState(
          {
            show_mark_calculate: false,
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({
          saving: false,
        });
        showError(error);
      });
  }
  requestMarkCheckRule() {
    //danh dau cac report phai thuc hien tinh toan check rule, nhung khong tinh toan lai du leiu

    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_DailyReport_MarkCheckRule",
        ThamSo: {
          DailyReportID: this.state.rowSelected.DailyReportID,
        },
      })
      .then((response) => {
        this.setState(
          {
            show_mark_checkrule: false,
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({
          saving: false,
        });
        showError(error);
      });
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <MySelectFilter
                id={"VoyageCode"}
                key={"VoyageCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Voyage}
                optionLabel="VoyageCode"
                optionValue="VoyageCode"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.ReportTime}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="ReportTime1"
                key="ReportTime1"
                fullWidth
                value={this.state.after.ReportTime1}
                onChange={(value) => {
                  if (value) {
                    if (this.state.ReportTime2 == null) {
                      this.state.after.ReportTime2 = value;
                    }
                  }
                  this.saveField("ReportTime1", value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{">>"}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="ReportTime2"
                key="ReportTime2"
                fullWidth
                value={this.state.after.ReportTime2}
                onChange={(value) => {
                  this.saveField("ReportTime2", value);
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if (item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={3}>
              {this.state.DR_TempReport_Error.length ? (
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => {
                    var log = this.state.DR_TempReport_Error[0].StatusLog;
                    this.setState({ ReportContent: log });
                  }}
                >
                  {buttonLabels.btnReportError}
                </Button>
              ) : this.state.UnCalculatedReport ? (
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => {
                    this.doRunReport();
                  }}
                >
                  {buttonLabels.btnCalculate +
                    ("(" + this.state.UnCalculatedReport + ")")}
                </Button>
              ) : null}
            </Grid>

            <Grid item xs={3}>
              <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
            </Grid>
            <Grid item xs={10}>
              <MySelectFilter
                id={"ReportTypeID"}
                key={"ReportTypeID"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_ReportType}
                optionLabel="ReportTypeName"
                optionValue="ReportTypeID"
                isMulti={true}
                placeholder=""
                value={this.state.after.ReportTypeID}
                onChange={(item) => {
                  console.log(item);
                  this.saveField("ReportTypeID", item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <FormControlLabel
                label="Contains alert only"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.CheckCount > 0}
                    onChange={(event) =>
                      this.saveField("CheckCount", event.target.checked ? 1 : 0)
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                label="Revised Consumption"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.RevisedROB > 0}
                    onChange={(event) =>
                      this.saveField("RevisedROB", event.target.checked ? 1 : 0)
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              {...ReactDataGrid_default(englishUI)}
              style={{
                height: this.state.showPreview
                  ? (parentHeight / 3) * 2 - 68 - 40
                  : parentHeight - 56 - 40,
                fontSize: 12,
              }}
              columns={this.columns_grid}
              pagination={true}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="IDReport"
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              enableSelection={true}
              onSelectionChange={({ data, selected, unselected }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" }, () =>
                    this.loadPreview()
                  );
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.showPreview ? (
            <>
              <Grid item xs={17}>
                <OutlinedInput
                  id="Preview"
                  key="Preview"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.ReportContent}
                  multiline
                  style={{
                    height: "100%",
                  }}
                  inputProps={{
                    style: {
                      height: parentHeight / 3,
                      overflow: "scroll",
                      backgroundColor: this.state.showPreview
                        ? "whitesmoke"
                        : "lightgray",
                      zIndex: 1,
                    },
                  }}
                ></OutlinedInput>
              </Grid>
              <Grid item xs={7}>
                <CheckResultControl
                  maxHeight={parentHeight / 3}
                  CheckResults={[]}
                  Attachs={this.state.Attachs}
                  onWarningClicked={(warning) => {
                    this.setState({
                      show_warning_confirm: true,
                      warningSelected: warning,
                    });
                  }}
                  onChange={() => {}}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              {this.state.hasQuyen ? (
                <>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.setState({
                          show_import_report: true,
                        });
                      }}
                    >
                      {buttonLabels.btnImport}
                    </Button>
                  </Grid>
                </>
              ) : null}
              {this.state.hasQuyen ? (
                <>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={this.state.rowSelected == undefined}
                      fullWidth
                      onClick={() => {
                        this.setState({
                          show_config_approve: true,
                        });
                      }}
                    >
                      {buttonLabels.btnApprove}
                    </Button>
                  </Grid>
                </>
              ) : null}
              {this.state.hasQuyen ? (
                <>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.rowSelected == undefined ||
                        this.state.rowSelected.DataStatus !== 1
                      }
                      fullWidth
                      onClick={() => {
                        this.setState({
                          show_mark_calculate: true,
                        });
                      }}
                    >
                      {buttonLabels.btnMarkCalculate}
                    </Button>
                  </Grid>
                </>
              ) : null}
              {this.state.hasQuyen ? (
                <>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.rowSelected == undefined ||
                        this.state.rowSelected.DataStatus !== 1
                      }
                      fullWidth
                      onClick={() => {
                        this.setState({
                          show_mark_checkrule: true,
                        });
                      }}
                    >
                      {buttonLabels.btnMarkCheckRule}
                    </Button>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  disabled={this.state.rowSelected == undefined}
                  fullWidth
                  onClick={() => {
                    window.open(
                      server.getRequest(
                        "Data/GetReportContent?ReportID=" +
                          this.state.rowSelected.ReportID
                      ),
                      "_blank"
                    );
                  }}
                >
                  To PDF
                </Button>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Show report content"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.showPreview}
                      onChange={(event) => {
                        this.setState({
                          showPreview: event.target.checked,
                          showLog: event.target.checked
                            ? false
                            : this.state.showLog,
                        });
                        localConfig.set(
                          "TempReportPage.showPreview",
                          event.target.checked
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                {this.state.DR_TempReport_Error.length ? (
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      //THUC HIEN XOA REPORT
                      server
                        .post("Data/DoRequest", {
                          Function: "Proc_DR_TempReport_Remove",
                          ThamSo: {
                            ReportID:
                              this.state.DR_TempReport_Error[0].ReportID,
                          },
                        })
                        .then((response) => {
                          this.doSearch();
                        })
                        .error((err) => handleServerError(err));
                    }}
                  >
                    FAILED RPT CLEAR
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={this.columns_grid}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_gridColumns,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
        {this.state.open_edit_departure ? (
          <DepartureReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_departure: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonatsea ? (
          <NoonAtSeaReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonatsea: false });
            }}
          />
        ) : null}
        {this.state.open_edit_arrival ? (
          <ArrivalReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_arrival: false });
            }}
          />
        ) : null}
        {this.state.open_edit_allfast ? (
          <AllfastReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_allfast: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonport ? (
          <NoonAtPortReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonport: false });
            }}
          />
        ) : null}
        {this.state.open_edit_shifting ? (
          <ShiftingReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_shifting: false });
            }}
          />
        ) : null}
        {this.state.show_edit_hieuchinh ? (
          <RevisedRobDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ show_edit_hieuchinh: false });
            }}
          />
        ) : null}
        {this.state.show_config_markread ? (
          <SimpleDialog
            confirm
            title="Xác nhận"
            message={
              "Xác nhận trạng thái đã đọc báo cáo:" +
              this.state.rowSelected.DailyReportID +
              " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.requestMarkRead();
                } else {
                  this.setState({ show_config_markread: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_config_approve ? (
          <SimpleDialog
            confirm
            title="Xác nhận"
            message={
              this.state.rowSelected.ReportStatus == 2
                ? "Hủy bỏ dữ liệu báo cáo ?"
                : "Duyệt sử dụng dữ liệu báo cáo ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.requestDuyet();
                } else {
                  this.setState({ show_config_approve: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_mark_calculate ? (
          <SimpleDialog
            confirm
            title="Xác nhận"
            message={"Tính toán lại số liệu báo cáo này ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.requestMarkCalculate();
                } else {
                  this.setState({ show_mark_calculate: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_mark_checkrule ? (
          <SimpleDialog
            confirm
            title="Xác nhận"
            message={"Kiểm tra lại rule-cảnh báo từ báo cáo này trở về sau ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.requestMarkCheckRule();
                } else {
                  this.setState({ show_mark_checkrule: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_confirm_process ? (
          <SimpleDialog
            confirm
            title="Xác nhận"
            message={
              "Xử lý lại dữ liệu báo cáo được chọn và các báo cáo liên quan?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.requestXuLy();
                } else {
                  this.setState({ show_config_approve: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.show_warning_confirm ? (
          <WarningConfirmDialog
            open={true}
            edittingRecord={this.state.warningSelected}
            QuyenXL={this.state.QuyenXL}
            close={(ketqua) => {
              this.setState({ show_warning_confirm: false });
              if (ketqua && this.props.onWarningChange) {
                //thong bao cho trang chu
                this.props.onWarningChange();
                this.loadPreview();
              }
            }}
          />
        ) : null}
        {this.state.show_import_report ? (
          <ImportReportDialog
            open={true}
            close={(ketqua) => {
              this.setState({ show_import_report: false });
              if (ketqua) {
                this.doSearch();
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(TempReportPage);
